import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { createGlobalStyle } from 'styled-components'

import '../styles/tachyons.min.css'

const GlobalStyle = createGlobalStyle`
  html {
    font-family: Inter,-apple-system,BlinkMacSystemFont,Roboto,Oxygen-Sans,"Helvetica Neue",Helvetica,Arial,sans-serif;
  }

  body {
      min-height: 100%;
  }

  h1, h2 {
    font-weight: 400;
  }

  .cb-sidebar {
    display: block;
    padding: 1rem;
  }

  .cb-content {
    width: 95%;
  }

  @media (min-width: 1024px) {
    .cb-sidebar {
      float: left;
      position: sticky;
      top: 0;
      left: 0;
      max-width: 300px;
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 0;
      font-size: 0.875rem;
    }

    .cb-content {
      float: left;
      padding-left: 1rem;
      width: calc(100% - 300px)
    }
  }
`

const TemplateWrapper = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        datoCmsSite {
          globalSeo {
            siteName
          }
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
        datoCmsHome {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          logo {
            alt
            fixed(width: 200) {
              srcSet
            }
          }
          introText
          copyright
        }
        datoCmsContact {
          email
          phone
          instagram
        }
        allDatoCmsWork(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              id
              title
              slug
              exhibition
            }
          }
        }
      }
    `}
    render={data => (
      <div className="cf">
        <GlobalStyle />
        <HelmetDatoCms
          favicon={data.datoCmsSite.faviconMetaTags}
          seo={data.datoCmsHome.seoMetaTags}
        />
        <div className="cb-sidebar">
          <header>
            <Link to="/" rel="home" className="black-80 f5 fw4 link">
              {(data.datoCmsHome.logo?.fixed.srcSet
                ?
                  <img
                    alt={data.datoCmsHome.logo.alt}
                    srcSet={data.datoCmsHome.logo.fixed.srcSet}
                    width={128}
                  />
                :
                  <>Cheska Brown</>
              )}
            </Link>

            {data.datoCmsHome.introText && (
              <p
                className="lh-copy black-60 mt3"
                dangerouslySetInnerHTML={{
                  __html: data.datoCmsHome.introText,
                }}
              />
            )}

            {data.datoCmsContact.email && (
              <a
                href={`mailto:${data.datoCmsContact.email}`}
                className="db mt3 dim black-60"
              >
                {data.datoCmsContact.email}
              </a>
            )}

            {data.datoCmsContact.phone && (
              <a
                href={`tel:${data.datoCmsContact.phone}`}
                className="db pt1 link dim black-60"
              >
                {data.datoCmsContact.phone}
              </a>
            )}

            <nav className="pt3">
              <ul className="list pa0 ma0">
                {data.allDatoCmsWork.edges.map(({ node: work }) => (
                  <li key={work.id} className="pt1">
                    <Link
                      to={`/work/${work.slug}`}
                      className="lh-solid dib link dim pv1 black-90"
                    >
                      {work.exhibition ? <>
                        <span className="f6 db lh-title">{work.exhibition}</span>
                        <span className="f6 black-60 lh-title">{work.title}</span>
                      </> : <>
                        <span className="f6 db lh-title">{work.title}</span>
                      </>}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            <ul className="mt3 list pa0 ma0">
              {data.datoCmsContact.instagram && (
                <li>
                  <a
                    target="_blank"
                    rel="me noopener noreferrer"
                    className="lh-solid dib link dim pv1 black-70"
                    href={data.datoCmsContact.instagram}
                  >
                    Instagram
                  </a>
                </li>
              )}
              <li>
                <Link
                  to="/contact"
                  className="lh-solid dib link dim pv1 black-70"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="lh-solid dib link dim pv1 black-70"
                >
                  About
                </Link>
              </li>
            </ul>
          </header>
          <footer className="dn db-l pt3 f6 black-50" style={{ marginTop: 'auto' }}>
            {data.datoCmsHome.copyright}
          </footer>
        </div>
        <main className="cb-content">
          {children}
        </main>
      </div>
    )}
  />
)

TemplateWrapper.propTypes = {
  children: PropTypes.object,
}

export default TemplateWrapper
